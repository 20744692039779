import storage, { STORAGE_KEY } from './storage';

const getStorageToken = () => {
  const exp = storage.get(STORAGE_KEY.EXPIRETIME);
  if (!exp || exp * 1000 < Date.now()) {
    return null;
  }
  const token = storage.get(STORAGE_KEY.TOKEN);
  return token;
};

const getStorageUserInfo = () => {
  const exp = storage.get(STORAGE_KEY.EXPIRETIME);
  if (!exp || exp * 1000 < Date.now()) {
    return null;
  }
  const userInfo = storage.get(STORAGE_KEY.USERINFO, true);
  return userInfo;
};

const sortByKey = (objectArr, key, type) => {
  const compare = () => (m, n) => {
    const a = m[key];
    const b = n[key];
    return type === 'descending' ? b - a : a - b;
  };
  return objectArr.sort(compare());
};

const verifySpaceString = (str) => {
  const spaceRex = new RegExp('^[ ]+$');
  return spaceRex.test(str);
};

export default {
  getStorageToken,
  getStorageUserInfo,
  sortByKey,
  verifySpaceString,
};
