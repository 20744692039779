import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { emitter, EMITTER_KEY } from '../../util';

const useStyles = makeStyles({
  headerBar: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignContent: 'center',
  },
  topFold: {
    position: 'absolute',
    top: '25px',
    left: '26px',
    padding: 0,
  },
  topFoldIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  logo: {
    height: '100%',
    width: '100%',
    maxHeight: '30px',
    maxWidth: '130px',
  },
  withTopFold: {
    marginLeft: '76px',
  },
  withoutTopFold: {
    marginLeft: '28px',
  },
});

export default function Header({ hasTopFold }) {
  const classes = useStyles();

  const toggleSidNavModel = () => {
    emitter.emit(EMITTER_KEY.TOGGLE_SIDENAV);
  };

  return (
    <Grid container className={classes.headerBar} spacing={3}>
      {hasTopFold
        ? (
          <IconButton className={classes.topFold} onClick={toggleSidNavModel}>
            <img className={classes.topFoldIcon} src="/Top-Fold.png" alt="Burberry" />
          </IconButton>
        ) : <></>}
      <img className={`${hasTopFold ? classes.withTopFold : classes.withoutTopFold} ${classes.logo}`} src="/burberry_logo.svg" alt="Burberry" />
    </Grid>
  );
}
