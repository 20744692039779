import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DatePicker } from '../../components/common';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: {
    height: '100%',
    marginRight: 70,
    color: '#000',
    fontSize: '18px',
    fontFamily: 'PingFang SC',
    fontWeight: 'bold',
    lineHeight: '24px',
  },
  tabsRoot: {
    flex: 1,
  },
  tabRoot: {
    fontSize: '16px',
    fontWeight: 'bold',
    padding: 0,
    margin: '6px 12px',
  },
  tabsIndicator: {
    backgroundColor: '#000',
    bottom: '15px',
    height: '1px',
  },
});

const HeaderChildren = ({
  title, tabArr, tabValue, setTabValue, date, setDate,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        {title}
      </div>
      <Tabs
        testID="tabs"
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
        }}
        value={tabValue}
        onChange={(event, value) => setTabValue(value)}
      >
        {
          tabArr.map(
            (tab) => <Tab key={tab} testID={tab} label={tab} classes={{ root: classes.tabRoot }} />,
          )
        }
      </Tabs>
      <DatePicker
        views={['year', 'month']}
        openTo="month"
        format="yyyy-MM"
        value={date}
        onChange={setDate}
      />
    </div>
  );
};

HeaderChildren.propTypes = {
  title: PropTypes.string.isRequired,
  tabArr: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

export default HeaderChildren;
