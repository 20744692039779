import React from 'react';

const LinkMessageItem = ({
  content: {
    description,
    image_url: imageUrl,
    link_url: linkUrl,
    title,
  },
}) => (
  <a href={linkUrl} target="blank">
    <img src={imageUrl} alt={title} tooltip={description} />
  </a>
);

export default LinkMessageItem;
