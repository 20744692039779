const API = {
  GET: {
    KEYWORD_LIST: '/api/keywords',
  },
  POST: {
    ADD_KEYWORD: '/api/keyword',
  },
  DELETE: {
    REMOVE_KEYWORD: '/api/keyword',
  },
};

export default API;
