import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import NoDataPage from './noDataPage';

const useStyles = makeStyles({
  tableListRow: {
    root: {
      borderBottom: 'none',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#F6F6F6',
    },
  },
  button: {
    borderBottom: '1px solid #000',
    borderRadius: 0,
    padding: 0,
    fontSize: 14,
  },
});

const StyledTableCell = withStyles(() => ({
  root: {
    paddingLeft: 30,
    padding: '16px',
    borderBottom: 'none',
    verticalAlign: 'middle',
    '&:first-child': {
      borderBottomLeftRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    '&:last-child': {
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px',
    },
  },
  head: {
    backgroundColor: '#FFF',
    color: '#999',
  },
  body: {
    fontSize: '14px',
  },
}))(TableCell);

const RankingStyleTableList = ({ tableTitle, tableInfoArr, noDataPageText }) => {
  const classes = useStyles();

  let render;
  if (!tableTitle || !tableInfoArr || (Array.isArray(tableInfoArr) && tableInfoArr.length === 0)) {
    render = <NoDataPage text={noDataPageText} />;
  } else {
    const tableField = Object.keys(tableTitle);

    render = (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableField.map((key) => (
              <StyledTableCell key={key}>
                <Typography>{tableTitle[key]}</Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableInfoArr.map((item, index) => (
            <TableRow className={classes.tableListRow} key={`table-row-${index + 1}`}>
              {
                tableField.map((key) => (
                  <StyledTableCell key={key}>
                    {item[key]}
                  </StyledTableCell>
                ))
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return render;
};

RankingStyleTableList.propTypes = {
  tableTitle: PropTypes.objectOf(PropTypes.string),
  tableInfoArr: PropTypes.arrayOf(PropTypes.object),
};

RankingStyleTableList.defaultProps = {
  tableTitle: null,
  tableInfoArr: null,
};

export default RankingStyleTableList;
