import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import keywordsEntryService from '../keywordsEntry/service';

const useStyles = makeStyles({
  container: {
    height: '32px',
    padding: '4px 0',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#6e6e6e',
      outline: '1px solid #708090',
    },
  },
  keywordButton: {
    borderRadius: '16px',
    textAlign: 'center',
    fontSize: '12px',
    fontFamily: 'PingFang SC',
    marginRight: 10,
    marginLeft: 10,

    backgroundColor: '#EEEEEE',
    borderColor: '#EEEEEE',
  },
  selectedKeywordButton: {
    backgroundColor: '#B79674',
    color: '#FFFFFF',
  },
  warningIconRoot: {
    marginRight: '4px',
    fontSize: '18px',
  },
});

const KeywordPicker = ({ onChange, initSelectedKeyword }) => {
  const classes = useStyles();
  const [keywordList, setKeywordList] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState(initSelectedKeyword);

  const handleSelectKeyword = (keyword) => {
    const handler = (item) => {
      setSelectedKeyword(item);
      if (onChange) onChange(item);
    };
    if (keyword === selectedKeyword) handler(null);
    else handler(keyword);
  };

  const fetchKeywordList = async () => {
    const results = await keywordsEntryService.getKeywordsList();
    const constructKeywordList = results.map((item) => ({
      id: item.id,
      category: item.category,
      keyword: item.keyword,
    }));
    setKeywordList(constructKeywordList);
  };

  useEffect(() => {
    (async () => { await fetchKeywordList(); })();
  }, []);

  return (
    <div className={classes.container}>
      {keywordList.map((item) => (
        <Button
          key={item.id}
          className={selectedKeyword === item.keyword
            ? `${classes.keywordButton} ${classes.selectedKeywordButton}`
            : classes.keywordButton}
          onClick={() => handleSelectKeyword(item.keyword)}
        >
          {item.category === 'sensitiveWord'
          && (
            <ErrorOutlineIcon
              classes={{ root: classes.warningIconRoot }}
            />
          )}
          {item.keyword}
        </Button>
      ))}
    </div>
  );
};

KeywordPicker.propTypes = {
  onChange: PropTypes.func,
  initSelectedKeyword: PropTypes.string,
};

KeywordPicker.defaultProps = {
  onChange: () => {},
  initSelectedKeyword: null,
};

export default KeywordPicker;
