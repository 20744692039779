import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles({
  content: {
    lineHeight: '24px',
  },
});

const UnsupportMessageItem = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item container direction="row" alignContent="center">
        <ErrorOutlineIcon />
        <Typography className={classes.content} variant="h5">该文件无法识别！</Typography>
      </Grid>
    </Grid>
  );
};

export default UnsupportMessageItem;
