import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SortButton from './sortButton';

const useStyles = makeStyles({
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: {
    flex: 1,
    fontFamily: 'PingFang SC',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
  },
});

const TableHeader = ({
  title,
  hasFrequencyOrder,
  onSort,
}) => {
  const classes = useStyles();

  const [sort, setSort] = useState('date');

  const handleSetSort = (targetType, direction) => {
    if (targetType !== sort) {
      setSort(targetType);
    }
    onSort(direction, targetType);
  };

  return (
    <div className={classes.headerContainer}>
      <span className={classes.titleContainer}>
        {title}
      </span>
      {hasFrequencyOrder
      && (
      <SortButton
        text="按频率排序"
        disabled={sort !== 'frequency'}
        setSort={(direction) => handleSetSort('frequency', direction)}
      />
      )}
      <SortButton
        text="按时间排序"
        disabled={sort !== 'date'}
        setSort={(direction) => handleSetSort('date', direction)}
      />
    </div>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string,
  hasFrequencyOrder: PropTypes.bool,
  onSort: PropTypes.func,
};

TableHeader.defaultProps = {
  title: '',
  hasFrequencyOrder: false,
  onSort: () => {},
};

export default TableHeader;
