import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Avatar } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import LinkMessageItem from './linkMessageItem';
import TextMessageItem from './textMessageItem';
import DocMessageItem from './docMessageItem';
import EmotionMessageItem from './emotionMessageItem';
import AudioMessageItem from './audioMessageItem';
import VideoMessageItem from './videoMessageItem';
// import CardMessageItem from './cardMessageItem';
// import calendarMessageItem from './calendarMessageItem';
// import todoMessageItem from './todoMessageItem';
import UnsupportMessageItem from './unsupportMessageItem';

const useStyles = makeStyles({
  conversationItemContainer: {
    width: '100%',
    padding: '24px 0',
  },
  conversation: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  avatar: {
    width: '56px',
    height: '56px',
  },
  conversationItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24px',
  },
  conversationInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  conversationInfoText: {
    fontSize: '.8rem',
    color: '#666666',
  },
  conversationContent: {
    background: '#F5F5F5',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    boxSizing: 'border-box',
    '& b': {
      background: '#FFF6AA',
      fontWeight: 'normal',
    },
  },
  nickName: {
    marginRight: '12px',
    marginBottom: '8px',
  },
});

const MSG_TYPES = {
  link: LinkMessageItem,
  docmsg: DocMessageItem,
  emotion: EmotionMessageItem,
  video: VideoMessageItem,
  // card: CardMessageItem,
  // calendar: calendarMessageItem,
  // todo: todoMessageItem,
  voice: AudioMessageItem,
  text: TextMessageItem,
};

const ConversationItem = ({ data }) => {
  const classes = useStyles();
  const { moment } = new MomentUtils();
  const {
    from, msgtype, content, attachment,
  } = data;
  const Item = MSG_TYPES[msgtype] || UnsupportMessageItem;

  return (
    <Container className={classes.conversationItemContainer}>
      <div className={classes.conversation}>
        <div className={classes.avatarItem}>
          <Avatar className={classes.avatar} alt={data.name} src={from.avatar} />
        </div>
        <div className={classes.conversationItem}>
          <div className={classes.conversationInfo}>
            <Typography className={`${classes.conversationInfoText} ${classes.nickName}`}>{from.name}</Typography>
            <Typography className={classes.conversationInfoText}>{moment(parseInt(data.msgtime, 10)).format('YYYY.MM.DD HH:mm:ss')}</Typography>
          </div>
          <div className={classes.conversationContent}>
            <Item attachment={attachment} content={content} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ConversationItem;
