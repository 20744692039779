import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import EventNoteIcon from '@material-ui/icons/EventNote';
import locale from 'date-fns/locale/zh-CN';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

const DatePicker = ({
  value,
  views,
  openTo,
  format,
  emptyLabel,
  onChange,
  theme,
  minDate,
  maxDate,
  useMedia,
}) => {
  const materialTheme = createMuiTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          width: useMedia ? '9rem' : '12rem',
          height: '3rem',
          color: '#CCCCCC',
          fontSize: '.9rem',
          padding: 0,
          '& > input': {
            padding: '0 0 0 16px',
          },
          '& > input.active': {
            color: '#666666',
          },
          '& > input.laptop': {
            fontSize: '.8rem',
          },
        },
      },
      MuiInputAdornment: {
        root: {
          '& > button': {
            padding: '12px 0',
          },
        },
      },
    },
  });

  const { moment } = new MomentUtils();
  const dateChange = (date) => {
    if (onChange) onChange(moment(date).format(format || 'YYYY-MM-DD'));
  };

  return (
    <ThemeProvider theme={theme || materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <KeyboardDatePicker
          views={views || ['year', 'month', 'date']}
          inputVariant="outlined"
          emptyLabel={emptyLabel || 'YYYY-MM-DD'}
          openTo={openTo || 'date'}
          format={format || 'yyyy-MM-dd'}
          value={value}
          onChange={dateChange}
          keyboardIcon={<EventNoteIcon fontSize="small" />}
          minDate={minDate}
          maxDate={maxDate}
          okLabel="确定"
          cancelLabel="取消"
          inputProps={{ className: `${value ? 'active' : ''} ${useMedia ? 'laptop' : ''}` }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
