import React from 'react';

const EmotionMessageItem = ({
  attachment,
  content: {
    height,
  },
}) => (
  <img
    alt={attachment}
    height={height}
    src={`/attachment/${attachment}`}
  />
);

export default EmotionMessageItem;
