import React from 'react';

const VideoMessageItem = ({
  attachment,
}) => (
  <video muted controls width="250">
    <source src={`/attachment/${attachment}`} type="video/mp4" />
  </video>
);

export default VideoMessageItem;
