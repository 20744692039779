import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const OutlineTimePicker = ({
  value,
  views,
  openTo,
  format,
  emptyLabel,
  onChange,
  useMedia,
}) => {
  const materialTheme = createMuiTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          width: useMedia ? '8rem' : '10rem',
          height: '3rem',
          color: '#CCCCCC',
          fontSize: '.9rem',
          padding: 0,
          '& > input': {
            padding: '0 0 0 16px',
          },
          '& > input.active': {
            color: '#666666',
          },
          '& > input.laptop': {
            fontSize: '.8rem',
          },
        },
      },
      MuiInputAdornment: {
        root: {
          '& > button': {
            padding: '12px 0',
          },
        },
      },
    },
  });
  const timeChange = (time) => {
    if (onChange) onChange(time.format(format));
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          views={views || ['hours', 'minutes', 'seconds']}
          inputVariant="outlined"
          emptyLabel={emptyLabel || ''}
          openTo={openTo || 'hours'}
          format={format || 'HH:mm:ss'}
          value={value}
          onChange={timeChange}
          ampm={false}
          okLabel="确定"
          cancelLabel="取消"
          inputProps={{ className: `${value ? 'active' : ''} ${useMedia ? 'laptop' : ''}` }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default OutlineTimePicker;
