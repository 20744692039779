import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '../card';
import Header from './header';
import Body from './body';
import TablePagination from './tablePagination';

const useStyles = makeStyles({
  tableContainer: {
    boxSizing: 'border-box',
    paddingTop: '32px',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingBottom: '40px',
  },
});

const Table = ({
  headerComponent,
  children,
  classes,
  hasPagination,
  dataAmount,
  pageSize,
  currentPage,
  onPageChange,
}) => {
  const styles = useStyles();

  return (
    <Card classes={`${styles.tableContainer} ${classes}`}>
      <Header>
        {headerComponent}
      </Header>
      <Body>
        {children}
      </Body>
      {hasPagination
      && (
      <TablePagination
        dataAmount={dataAmount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      )}
    </Card>
  );
};

Table.propTypes = {
  headerComponent: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.string,
  hasPagination: PropTypes.bool,
  dataAmount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

Table.defaultProps = {
  headerComponent: null,
  children: null,
  classes: '',
  hasPagination: false,
  dataAmount: 0,
  pageSize: null,
  currentPage: null,
  onPageChange: () => {},
};

export default Table;
