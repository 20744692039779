import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MomentUtils from '@date-io/moment';

import Popup from '../popup';
import ConversationItem from './components/conversationItem';

const useStyles = makeStyles({
  conversationPopupContainer: {
  },
  popup: {
    width: '41.7vw',
    height: '80vh',
  },
  top: {
    width: '100%',
    minHeight: '52px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 24px 0 40px',
    boxSizing: 'border-box',
  },
  closeIcon: {
    width: '15px',
    height: '15px',
    color: '#000000',
  },
  infoLine: {
    width: '100%',
    minHeight: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px 0 40px',
    boxSizing: 'border-box',
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
  },
  related: {
    display: 'flex',
    flexDirection: 'row',
  },
  relatedCount: {
    marginRight: '12px',
  },
  toNextIcon: {
    width: '5px',
    height: '5px',
    marginRight: '16px',
  },
  toLastIcon: {
    width: '5px',
    height: '5px',
    marginLeft: '16px',
  },
  conversationBody: {
    overflow: 'auto',
    maxHeight: '702px',
    width: '100%',
  },
  conversation: {
    width: '100%',
    padding: '0 40px',
    boxSizing: 'border-box',
  },
});

const CoversationPopup = ({
  data,
  isShow,
  closePopup,
  keywords,
}) => {
  const classes = useStyles();
  const { moment } = new MomentUtils();

  const onClosePopup = () => {
    if (closePopup) closePopup();
  };

  const toNextKeyword = () => { };

  const toLastKeyword = () => { };

  const formatDate = moment(parseInt(data[0].msgtime, 10)).format('YYYY-MM-DD');

  return (
    <Container className={classes.conversationPopupContainer}>
      <Popup classes={classes.popup} isShow={isShow}>
        <div className={classes.top}>
          <Typography variant="h3">{keywords ? `关键词: ${keywords}` : ''}</Typography>
          <IconButton className={classes.closeIcon} onClick={onClosePopup}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>

        <div className={classes.infoLine}>
          <Typography variant="h5">{formatDate}</Typography>
          <span className={classes.related}>
            <Typography className={classes.relatedCount}>{`${data.length}条相关聊天记录:`}</Typography>
            <IconButton className={classes.toNextIcon} onClick={toNextKeyword}>
              <ExpandMoreIcon className={classes.closeIcon} />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton className={classes.toLastIcon} onClick={toLastKeyword}>
              <ExpandLessIcon className={classes.closeIcon} />
            </IconButton>
          </span>
        </div>
        <div className={classes.conversationBody}>
          {
            data.map((conversationItem) => (
              <div key={conversationItem.id} className={classes.conversation}>
                <ConversationItem data={conversationItem} />
              </div>
            ))
          }
        </div>
      </Popup>
    </Container>
  );
};

export default CoversationPopup;
