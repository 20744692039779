import React, { useEffect, useState } from 'react';
import AMR from './amrnb.min';

const fetchBlob = async (url) => {
  const result = await fetch(url);
  const blob = await result.blob();
  return (blob);
};

const readBlob = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    resolve(data);
  };
  reader.onerror = reject;
  reader.readAsArrayBuffer(blob);
});

const convertAmrBlobToWav = async (blob) => {
  const data = await readBlob(blob);
  return AMR.toWAV(data);
};

const AudioMessageItem = ({
  attachment,
}) => {
  const [audioBlob, setAudioBlob] = useState(null);

  useEffect(() => {
    const url = `/attachment/${attachment}`;
    fetchBlob(url)
      .then(async (blob) => {
        const buffer = await convertAmrBlobToWav(blob);
        setAudioBlob(URL.createObjectURL(new Blob([buffer], { type: 'audio/x-wav' })));
      });
  }, [attachment]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls="controls" src={audioBlob} type="audio/x-wav" />
  );
};

export default AudioMessageItem;
