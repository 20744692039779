/* eslint-disable no-case-declarations */
import { ACTION_TYPE, ROUTE_PATH, SIDENAV_TABS } from '../../constants';

const initialState = {
  chooseSideNavTab: {
    tabId: 'ranking',
    router: ROUTE_PATH.RANKING,
  },
};

const getDefaultState = (defaultState) => {
  const { href } = window.location;
  const state = SIDENAV_TABS.find((tab) => href.indexOf(tab.router));

  return state || defaultState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SIDE_NAV.CHOOSE_TAB:
      return { chooseSideNavTab: action.chooseSideNavTab };

    default:
      return getDefaultState(state);
  }
};
