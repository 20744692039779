import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles({
  dialogTableItemContainer: {
    width: '100%',
    minHeight: '112px',
    display: 'flex',
    flexDirection: 'row',
    background: '#F6F6F6',
    borderRadius: '8px',
    marginBottom: '8px',
  },
  dateItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialoguePeopleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '24px',
  },
  avatar: {
    width: '30px',
    height: '30px',
    marginRight: '9px',
  },
  dialoguePeople: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '6px',
  },
  dialogueItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '31px',
    paddingBottom: '16px',
  },
  dialogueRelated: {
    fontSize: '0.75rem',
    color: '#999999',
  },
  detailItem: {
    padding: '12px 0',
  },
  detailIcon: {
    width: '22px',
    height: '22px',
  },
  detailText: {
    fontSize: '0.75rem',
  },
  detailContent: {
    width: '100%',
    height: '100%',
    borderLeft: '1px solid #DDDDDD',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    display: 'block',
    lineHeight: 1,
  },
  lineHeight: {
    lineHeight: 2,
  },
});

const DialogMessageItem = ({ data, viewDetail }) => {
  const classes = useStyles();
  const { moment } = new MomentUtils();
  const { from, to } = data;

  const onViewDetail = (item) => {
    if (!(from && to)) {
      return;
    }
    if (viewDetail) viewDetail(item);
  };
  const date = moment(parseInt(data.msgtime, 10)).format('YYYY.MM.DD');
  const time = moment(parseInt(data.msgtime, 10)).format('HH:mm:ss');

  return (
    <Grid container className={classes.dialogTableItemContainer}>
      <Grid container item xs={2} className={classes.dateItem}>
        <div>
          <Typography variant="h5" className={classes.lineHeight}>{`${date}`}</Typography>
          <Typography variant="h5" className={classes.lineHeight}>{`${time}`}</Typography>
        </div>
      </Grid>
      <Grid container item xs={2} className={classes.dialoguePeopleItem}>
        {from
          && (
            <div className={classes.dialoguePeople}>
              <Avatar className={classes.avatar} alt={from.name} src={from.avatar} />
              <Typography variant="h5" className={classes.lineHeightM}>{from.name}</Typography>
            </div>
          )}
        {to
          && (
            <div className={classes.dialoguePeople}>
              <Avatar className={classes.avatar} alt={to.name} src={to.avatar} />
              <Typography variant="h5">{to.name}</Typography>
            </div>
          )}
      </Grid>
      <Grid container item xs={6} className={classes.dialogueItem}>
        <Typography variant="h5">{data.content.content}</Typography>
        {/* <Typography className={classes.dialogueRelated}>
          {`${data.count || 0}条相关聊天记录`}
        </Typography> */}
      </Grid>
      <Grid container item xs={2} className={classes.detailItem}>
        <div className={classes.detailContent}>
          <Button className={classes.button} onClick={() => onViewDetail(data)}>
            <img className={classes.detailIcon} src="/Details.png" alt="查看详情" />
            <Typography className={classes.detailText}>查看详情</Typography>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default DialogMessageItem;
