/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { useParams } from 'react-router-dom';

import {
  CoversationPopup,
  DialogPanel,
  Card,
  DatePicker,
} from '../../components/common';
import KeywordPicker from './keywordPicker';
import service from '../search/service';
import { load } from '../../util';

const { moment } = new MomentUtils();

const useStyles = makeStyles(() => ({
  alertModule: {
  },
  card: {
    marginBottom: '24px',
  },
  searchLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '42px 40px',
    boxSizing: 'border-box',
  },
  searchDate: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
    '& > span': {
      fontSize: '1.75rem',
      margin: '0 10px',
    },
  },
  searchButton: {
    width: '200px',
    height: '50px',
    borderRadius: '25px',
  },
  clearButton: {
  },
  clearIcon: {
    width: '29px',
    height: '29px',
    marginLeft: '18px',
  },
  clearText: {
    fontSize: '.9rem',
    textDecoration: 'underline',
  },
  keywordLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '21px 20px',
    boxSizing: 'border-box',
  },
  keyword: {
    fontSize: '1rem',
    fontWeight: 700,
    minWidth: '70px',
  },
}));

const AlertModule = () => {
  const classes = useStyles();
  const { keyword: keywordParam } = useParams();
  const { date: dateParam } = useParams();
  const [popupShow, setPopupShow] = useState(false);
  const [conversationData, setConversationData] = useState({ results: null });
  const [detailData, setDetailData] = useState(null);
  const [searchCondition, setSearchCondition] = useState({
    page: 1,
    pageSize: 10,
    orderDirection: 'desc',
    startDate: dateParam ? moment(dateParam).startOf('month').format('YYYY-MM-DD') : '',
    endDate: dateParam ? moment(dateParam).endOf('month').format('YYYY-MM-DD') : '',
    keyword: keywordParam === ':keyword' ? null : keywordParam,
  });

  const searchConditionChange = (key, value) => {
    const condition = Object.assign(searchCondition, { [key]: value });
    setSearchCondition({ ...condition });
  };

  const keywordChange = (value) => {
    const condition = Object.assign(searchCondition, { keyword: value });
    setSearchCondition({ ...condition });
    setConversationData({});
  };

  const doSearch = async (condition) => {
    load.open();
    const data = await service.search(condition);
    load.close();
    setConversationData(data);
  };

  const sortByMsgtime = (direction) => {
    const condition = {
      ...searchCondition,
      orderBy: 'msgtime',
      orderDirection: direction,
    };
    setSearchCondition(condition);
    doSearch(condition);
  };

  const viewDetail = useCallback(async (item) => {
    load.open();
    const data = await service.getConversationByFromToDate(item);
    setDetailData(data);
    load.close();
    setPopupShow(true);
  }, []);

  const closeConversationPopup = () => {
    setPopupShow(false);
  };

  const resetCondition = () => {
    setSearchCondition({
      ...searchCondition,
      startDate: '',
      endDate: '',
    });
    setConversationData({});
  };

  const canSearch = () => {
    const res = !(searchCondition.keyword && searchCondition.startDate && searchCondition.endDate);
    return res;
  };

  const onPageChange = (page) => {
    const condition = {
      ...searchCondition,
      page,
    };
    setSearchCondition(condition);
    doSearch(condition);
  };

  useEffect(() => {
    if (keywordParam && keywordParam === searchCondition.keyword) {
      (async () => { await doSearch(searchCondition); })();
    }
  }, [keywordParam, searchCondition]);

  return (
    <div className={classes.alertModule}>
      {
        popupShow && detailData ? (
          <CoversationPopup
            classes={classes.popup}
            data={detailData}
            isShow={popupShow}
            closePopup={closeConversationPopup}
          />
        ) : ''
      }
      <Card classes={classes.card}>
        <div className={classes.keywordLine}>
          <Typography className={classes.keyword}>关键词:</Typography>
          <KeywordPicker
            onChange={(keyword) => keywordChange(keyword)}
            initSelectedKeyword={searchCondition.keyword}
          />
        </div>
      </Card>
      <Card classes={classes.card}>
        <div className={classes.searchLine}>
          <div className={classes.searchDate}>
            <DatePicker
              value={searchCondition.startDate || null}
              maxDate={searchCondition.endDate}
              onChange={(value) => (searchConditionChange('startDate', value))}
            />
            <span> ~ </span>
            <DatePicker
              value={searchCondition.endDate || null}
              minDate={searchCondition.startDate}
              onChange={(value) => (searchConditionChange('endDate', value))}
            />
          </div>
          <div>
            <Button
              className={classes.searchButton}
              variant="contained"
              color="primary"
              disabled={canSearch()}
              disableElevation
              onClick={() => doSearch(searchCondition)}
            >
              搜索
            </Button>
            <Button className={classes.clearButton} onClick={resetCondition}>
              <img className={classes.clearIcon} src="/Delete.png" alt="清除" />
              <Typography className={classes.clearText}>清除</Typography>
            </Button>
          </div>
        </div>
      </Card>
      {
        conversationData.results
          ? (
            <DialogPanel
              hasPagination
              currentPage={searchCondition.page}
              onPageChange={onPageChange}
              pageSize={searchCondition.pageSize}
              dataAmount={conversationData.total}
              data={conversationData.results}
              title={`搜索结果 (${conversationData.results.length}个)`}
              onSort={sortByMsgtime}
              viewDetail={viewDetail}
              keywords={searchCondition.keyword}
            />
          )
          : ''
      }
    </div>
  );
};

export default AlertModule;
