import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import {
  Card,
  Table,
  RankingStyleTableList,
  TableOperationButton,
  TableHeader,
} from '../../components/common';
import { toast, help, load } from '../../util';
import SelectorInput from './components/selectorInput';
import LetterPicker from './components/letterPicker';
import service from './service';

const useStyles = makeStyles({
  card: {
    marginBottom: '24px',
  },
  keywordsEntry: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px 0',
    '& .MuiButton-root': {
      width: '295px',
      height: '50px',
      borderRadius: '25px',
    },
    '& > div': {
      marginBottom: '44px',
    },
  },
  keywordsEntryTitle: {
    fontFamily: 'PingFang SC',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
  },
});

const orderMap = {
  frequency: 'matchedCount',
  date: 'createdAt',
};

const tableTitle = {
  keyword: '关键词',
  category: '类别',
  frequency: '出现次数',
  date: '录入日期',
  history: '历史记录',
  operation: '操作',
};

const KeywordsEntryModule = () => {
  const classes = useStyles();
  const inputRef = useRef();

  const [wordEntry, setWordEntry] = useState({ wordType: 'key', word: '' });
  const [dataAmount, setDataAmount] = useState(0);
  const [query, setQuery] = useState({
    page: 1, pageSize: 10, orderBy: 'createdAt', orderDirection: 'desc',
  });
  const [wordsList, setWordsList] = useState(null);

  const inputChange = (param) => {
    setWordEntry(param);
  };

  const fetchPageDataByApi = useCallback(async (queryParas) => {
    load.open();
    const res = await service.getKeywordsList(queryParas);
    load.close();
    const { results, total } = res;
    setDataAmount(total);
    setWordsList(results);
  }, []);

  useEffect(() => {
    (async () => { await fetchPageDataByApi(query); })();
  }, [query, fetchPageDataByApi]);

  const addKeywordByApi = async () => {
    load.open();
    if (!wordEntry.word.length) return;

    const result = await service.addKeyword({
      keyword: wordEntry.word,
      category: wordEntry.wordType,
    });
    if (result) {
      await fetchPageDataByApi(query);
      toast.success('创建成功。');
      inputRef.current.value = '';
    }
  };

  const removeKeywordByApi = async (keywordId) => {
    const result = await service.removeKeyword({ id: keywordId });
    if (result) {
      setQuery({ ...query, page: 1 });
      toast.success('删除成功。');
    }
  };

  const pageDataToUse = wordsList ? wordsList.map((item) => ({
    keyword: item.keyword,
    category: item.category === 'sensitive' ? '警示词' : '关键词',
    frequency: item.matchedCount,
    date: item.createdAt.slice(0, 10),
    history: <TableOperationButton buttonText="查看对话" keyword={item.keyword} />,
    operation: <TableOperationButton buttonText="删除" onClick={() => removeKeywordByApi(item.id)} />,
  })) : null;

  return (
    <div className={classes.keywordsModule}>
      <Card classes={classes.card}>
        <div className={classes.keywordsEntry}>
          <div testID="keywordsEntryTitle" className={classes.keywordsEntryTitle}>
            录入词汇
          </div>
          <div>
            <SelectorInput
              onChange={inputChange}
              ref={inputRef}
            />
          </div>
          <Button variant="contained" color="primary" disabled={!wordEntry || wordEntry.word.length === 0 || help.verifySpaceString(wordEntry.word)} onClick={addKeywordByApi}>添加</Button>
        </div>
      </Card>
      <Card>
        <Table
          headerComponent={(
            <TableHeader
              hasFrequencyOrder
              title={`已录入词库(共${dataAmount}个)`}
              timeOrderChange={() => {}}
              frequencyOrderChange={() => {}}
              onSort={(direction, targetType) => setQuery({
                ...query, page: 1, orderBy: orderMap[targetType], orderDirection: direction,
              })}
            />
          )}
          hasPagination
          dataAmount={dataAmount}
          pageSize={query.pageSize}
          currentPage={query.page}
          onPageChange={(page) => setQuery({ ...query, page })}
        >
          <>
            <LetterPicker
              handleSelectLetter={(letter) => {
                setQuery({
                  ...query,
                  page: 1,
                  initial: letter,
                });
              }}
            />
            { pageDataToUse
              && (
              <RankingStyleTableList
                tableTitle={tableTitle}
                tableInfoArr={pageDataToUse}
              />
              )}
          </>
        </Table>
      </Card>
    </div>
  );
};

export default KeywordsEntryModule;
