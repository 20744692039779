import { http } from '../../util/index';
import API from './constants';

const getKeywordsRanking = async (query) => {
  const url = API.GET.KEYWORDS_RANKING;
  const result = await http.get(url, { params: query });
  return result;
};

export default getKeywordsRanking;
