import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiAlert: {
      root: {
        '& > * + *': {
          marginTop: 2,
        },
      },
      filledError: {
        backgroundColor: '#F44336',
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          color: '#000000',
          borderColor: '#000000',
          backgroundColor: '#C4C4C4',
        },
      },
      outlined: {
        fontSize: '0.8rem',
        maxWidth: '200px',
        flex: 1,
        borderRadius: '20px',
        width: '100%',
        fontWeight: 550,
        color: '#000000',
        borderColor: '#000000',
        backgroundColor: '#C4C4C4',
        '&:hover': {
          borderColor: '#000000',
        },
      },
      outlinedPrimary: {
        fontSize: '0.8rem',
        maxWidth: '200px',
        flex: 1,
        borderRadius: '20px',
        width: '100%',
        backgroundColor: '#000000',
        fontWeight: 550,
        color: '#FFFFFF',
        borderColor: '#000000',
        '&:hover': {
          borderColor: '#000000',
          backgroundColor: '#000000',
        },
      },
      outlinedSecondary: {
        fontSize: '0.8rem',
        maxWidth: '200px',
        flex: 1,
        borderRadius: '20px',
        width: '100%',
        backgroundColor: '#DBDFAA',
        borderColor: '#000000',
        color: '#000000',
        fontWeight: 550,
        '&$disabled': {
          borderColor: '#000000',
        },
        '&:hover': {
          backgroundColor: '#DBDFAA',
          borderColor: '#000000',
        },
      },
    },
  },
  breakpoints: {
    values: {
      lg: 1024,
      xl: 1500,
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#000000',
    },
    background: {
      paper: '#FFFFFF',
      default: '#E5E5E5',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h1: {
      fontWeight: 700,
      fontSize: '1.25rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.1rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.0rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '0.9rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
    body1: {
      fontWeight: 350,
      fontSize: '1.0rem',
    },
    body2: {
      fontWeight: 350,
      fontSize: '0.75rem',
    },
  },
});

export default theme;
