import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Card from './card';

const useStyles = makeStyles({
  PopupContainer: {
  },
  card: {
    position: 'fixed',
    top: '10vh',
    zIndex: '1002',
    boxShadow: 'none',
    borderRadius: '12px',
  },
  mask: {
    position: 'fixed',
    zIndex: '1001',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: '#000000',
    opacity: '.5',
  },
});

const Popup = ({ children, isShow, classes }) => {
  const styles = useStyles();

  return (
    isShow
      ? (
        <Container className={styles.PopupContainer}>
          <Card classes={`${styles.card} ${classes}`}>
            {children}
          </Card>
          <div className={styles.mask} />
        </Container>
      ) : ''
  );
};

export default Popup;
