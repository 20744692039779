import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import authPages from '../modules/auth';
import rankingPages from '../modules/ranking';
import searchPages from '../modules/search';
import alertPages from '../modules/alert';
import keywordsEntryPages from '../modules/keywordsEntry';
import { ROUTE_PATH } from '../constants/index';
import RouteGuard from './routeGuard';

const pages = [...authPages, ...rankingPages, ...alertPages, ...keywordsEntryPages, ...searchPages];

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to={ROUTE_PATH.LOGIN} />
        {pages.map((page) => (
          <RouteGuard exact path={page.path} page={page} key={page.path} />
        ))}
      </Switch>
    </BrowserRouter>
  );
}
