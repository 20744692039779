import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cardContainer: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '3px 3px 3px 1px #D9D8D8',
    minWidth: '800px',
  },
});

const Card = ({ children, classes }) => {
  const styles = useStyles();

  return (
    <div className={`${styles.cardContainer} ${classes}`}>
      {children}
    </div>
  );
};

export default Card;
