import { ACTION_TYPE, ROUTE_PATH } from '../../constants';

const defaultSideNavTab = {
  tabId: 'ranking',
  router: ROUTE_PATH.RANKING,
};

export default (chooseSideNavTab) => ({
  type: ACTION_TYPE.SIDE_NAV.CHOOSE_TAB,
  chooseSideNavTab: chooseSideNavTab || defaultSideNavTab,
});
