import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  button: {
    borderBottom: '1px solid #000',
    borderRadius: 0,
    padding: 0,
    fontSize: 14,
    minWidth: 0,
  },
});

const TableOperationButton = ({
  buttonText,
  onClick,
  keyword,
  date,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      className={classes.button}
      onClick={() => {
        if (onClick) onClick();
        if (keyword) {
          history.push(date ? `/alert/${keyword}/${date}` : `/alert/${keyword}`);
        }
      }}
    >
      {buttonText}
    </Button>
  );
};

TableOperationButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
TableOperationButton.defaultProps = {
  onClick: null,
};

export default TableOperationButton;
