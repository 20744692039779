import React, { useState, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  selectorInputContainer: {
    '& .MuiOutlinedInput-input': {
      width: '580px',
      height: '50px',
      borderRadius: '4px',
      padding: '0 0 0 16px',
      boxSizing: 'border-box',
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 0,
        height: '100%',
        borderRight: '1px solid #C4C4C4',
      },
      '&:after': {
        borderBottom: 0,
        borderRight: '1px solid #C4C4C4',
        height: '100%',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },
  },
  select: {
    '& .MuiSelect-root': {
      width: '90px',
    },
    '& .MuiSelect-select': {
      paddingRight: '13px',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '13px',
      color: '#000',
    },
    '& .MuiInput-input': {
      backgroundColor: '#FFFFFF',
      fontWeight: 'bold',
    },
  },
});

const SelectorInput = ({ onChange, forwardedRef }) => {
  const classes = useStyles();
  const [wordType, setWordType] = useState('key');

  const handleSelectChange = (event) => {
    setWordType(event.target.value);
  };

  const handleEditWord = (event) => {
    onChange({ wordType, word: event.target.value });
  };

  const renderSelector = () => (
    <InputAdornment position="start">
      <Select
        value={wordType}
        onChange={handleSelectChange}
        displayEmpty
        className={classes.select}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value="key">关键词</MenuItem>
        <MenuItem value="sensitive">警示词</MenuItem>
      </Select>
    </InputAdornment>
  );

  return (
    <div className={classes.selectorInputContainer}>
      <OutlinedInput
        onChange={handleEditWord}
        startAdornment={renderSelector()}
        inputRef={forwardedRef}
      />
    </div>
  );
};

export default forwardRef((props, ref) => (
  // eslint-disable-next-line
  <SelectorInput {...props} forwardedRef={ref} />
));
