/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  inputContainer: {
    '& .MuiInputLabel-root': {
      color: '#000000',
      fontSize: '1.2rem',
      fontWeight: 700,
      marginBottom: '12px',
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '26px',
    },
    '& > input.laptop': {
      width: '8rem',
      fontSize: '.8rem',
    },
  },
  input: {
    width: '10rem',
    height: '3rem',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '.9rem',
    padding: '13px 16px',
    boxSizing: 'border-box',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const Input = ({
  label,
  useMedia,
  inputChange,
  ...props
}) => {
  const classes = useStyles();
  const { moment } = new MomentUtils();
  const now = moment().format('x');

  const onInputChange = (event) => {
    if (inputChange) inputChange(event.target.value);
  };

  return (
    <div className={classes.inputContainer}>
      <FormControl className={classes.margin}>
        <InputLabel shrink htmlFor={`bootstrap-input-${now}`}>{label}</InputLabel>
        <BootstrapInput
          id={`bootstrap-input-${now}`}
          onChange={onInputChange}
          inputProps={{ className: useMedia ? 'laptop' : '' }}
          {...props}
        />
      </FormControl>
    </div>

  );
};

export default Input;
