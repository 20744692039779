import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '52px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    overflowY: 'hidden',
    borderBottom: '1px solid #DDD',
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#6e6e6e',
      outline: '1px solid #708090',
    },
  },
  buttonContainer: {
    flex: 1,
    height: '100%',
    minWidth: '40px',
    marginRight: '6px',
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  letter: {
    minWidth: 0,
    height: '30px',
    width: '30px',
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: '16px',
    color: '#999999',
  },
  selectedLetter: {
    backgroundColor: '#000',
    color: '#fff',
  },
});

const renderLetter = () => {
  const letterArr = [];
  for (let i = 0; i <= 25; i += 1) {
    letterArr.push(String.fromCharCode((65 + i)));
  }
  return letterArr;
};

const LetterPicker = ({ handleSelectLetter }) => {
  const classes = useStyles();
  const [selectedLetter, setSelectedLetter] = useState(null);

  const handlePressLetter = (letter) => {
    if (letter === selectedLetter) {
      setSelectedLetter(null);
      handleSelectLetter(null);
    } else {
      setSelectedLetter(letter);
      handleSelectLetter(letter);
    }
  };

  return (
    <div className={classes.container}>
      {renderLetter().map(((letter) => (
        <div className={classes.buttonContainer} key={letter}>
          <Button
            className={
                selectedLetter === letter
                  ? `${classes.letter} ${classes.selectedLetter}`
                  : classes.letter
                }
            onClick={() => handlePressLetter(letter)}
          >
            { letter }
          </Button>
        </div>
      )))}
    </div>
  );
};

export default LetterPicker;
