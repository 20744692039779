import React, { useCallback, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CoversationPopup,
  DialogPanel,
  Card,
  Input,
  DatePicker,
  OutlineTimePicker,
} from '../../components/common';
import service from './service';
import { load } from '../../util';

const useStyles = makeStyles(() => ({
  searchModule: {
    marginBottom: '24px',
  },
  searchPanel: {
    width: '100%',
    padding: '40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  searchColumn: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:first-child': {
      marginBottom: '16px',
    },
  },
  dateSearchColumn: {
    justifyContent: 'flex-end',
  },
  dateLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '8px',
    '& > span': {
      margin: '0 12px',
      fontSize: '1.75rem',
    },
  },
  searchButton: {
    width: '100%',
    marginTop: '40px',
    justifyContent: 'center',
    '& .MuiButton-root': {
      width: '347px',
      height: '50px',
      borderRadius: '25px',
    },
  },
  clearConditions: {
    textAlign: 'right',
    '& .MuiButton-root': {
      width: 'auto',
    },
  },
  clearButton: {
    textAlign: 'right',
  },
  clearIcon: {
    width: '29px',
    height: '29px',
    marginLeft: '18px',
  },
  clearText: {
    fontSize: '.9rem',
    textDecoration: 'underline',
  },
}));

const SearchModule = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1625px)');
  const [popupShow, setPopupShow] = useState(false);
  const [conversationData, setConversationData] = useState({ results: null });
  const [searchCondition, setSearchCondition] = useState({
    page: 1,
    pageSize: 10,
    orderDirection: 'desc',
  });
  const [detailData, setDetailData] = useState(null);
  const fromRef = useRef('');
  const toRef = useRef('');

  const viewDetail = useCallback(async (item) => {
    load.open();
    const data = await service.getConversationByFromToDate(item);
    load.close();
    setDetailData(data);
    setPopupShow(true);
  }, []);

  const closeConversationPopup = () => {
    setPopupShow(false);
  };

  const searchConditionChange = (key, value) => {
    const condition = Object.assign(searchCondition, { [key]: value });
    setSearchCondition({ ...condition });
  };

  const doSearch = async (condition) => {
    load.open();
    const data = await service.search(condition || searchCondition);
    load.close();
    setConversationData(data);
  };

  const onSort = (direction) => {
    const condition = {
      ...searchCondition,
      orderDirection: direction,
    };
    setSearchCondition(condition);
    doSearch(condition);
  };

  const onPageChange = (page) => {
    const condition = {
      ...searchCondition,
      page,
    };
    setSearchCondition(condition);
    doSearch(condition);
  };

  const resetCondition = () => {
    fromRef.current.value = '';
    toRef.current.value = '';
    setSearchCondition({
      page: 1,
      pageSize: 10,
      orderDirection: 'desc',
    });
    setConversationData({});
  };

  return (
    <div className={classes.searchModule}>
      {
        popupShow && detailData ? (
          <CoversationPopup
            classes={classes.popup}
            data={detailData}
            isShow={popupShow}
            closePopup={closeConversationPopup}
          />
        ) : ''
      }
      <Card classes={classes.searchModule}>
        <Grid container className={classes.searchPanel}>
          <Input
            label="对话人1"
            placeholder="对话人1"
            inputChange={(value) => (searchConditionChange('from', value))}
            inputRef={fromRef}
            useMedia={matches}
          />
          <Input
            label="对话人2"
            placeholder="对话人2"
            inputChange={(value) => (searchConditionChange('to', value))}
            inputRef={toRef}
            useMedia={matches}
          />
          <div>
            <Typography variant="h4">日期</Typography>
            <div className={classes.dateLine}>
              <DatePicker
                value={searchCondition.startDate || null}
                maxDate={searchCondition.endDate}
                onChange={(value) => (searchConditionChange('startDate', value))}
                useMedia={matches}
              />
              <span> ~ </span>
              <DatePicker
                value={searchCondition.endDate || null}
                minDate={searchCondition.startDate}
                onChange={(value) => (searchConditionChange('endDate', value))}
                useMedia={matches}
              />
            </div>
          </div>
          <div>
            <Typography variant="h4">时间</Typography>
            <div className={classes.dateLine}>
              <OutlineTimePicker
                emptyLabel="00:00:00"
                value={searchCondition.startTime || null}
                onChange={(value) => (searchConditionChange('startTime', value))}
                useMedia={matches}
              />
              <span> ~ </span>
              <OutlineTimePicker
                emptyLabel="23:59:59"
                value={searchCondition.endTime || null}
                onChange={(value) => (searchConditionChange('endTime', value))}
                useMedia={matches}
              />
            </div>
          </div>
        </Grid>
        <Grid container className={classes.searchPanel}>
          <Grid container item xs={12} className={classes.searchButton}>
            <Grid container item xs={4} className={classes.empty} />
            <Grid item xs={4}>
              <Button variant="contained" color="primary" disabled={!searchCondition.from && !searchCondition.to} disableElevation onClick={() => doSearch()}>搜索</Button>
            </Grid>
            <Grid item xs={4} className={classes.clearConditions}>
              <Button className={classes.clearButton} onClick={resetCondition}>
                <img className={classes.clearIcon} src="/Delete.png" alt="清除" />
                <Typography className={classes.clearText}>清除所有选项</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {
        conversationData.results
          ? (
            <DialogPanel
              data={conversationData.results}
              title={`搜索结果 (${conversationData.results.length}个)`}
              onSort={onSort}
              viewDetail={viewDetail}
              hasPagination
              dataAmount={conversationData.results.length}
              pageSize={searchCondition.pageSize}
              currentPage={searchCondition.page}
              onPageChange={onPageChange}
              noDataPageText="- 暂无搜索结果 -"
            />
          )
          : ''
      }
    </div>
  );
};

export default SearchModule;
