import React, { useState, useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RankingSame from '@material-ui/icons/Remove';

import HeaderChildren from './headerChildren';
import { Table, RankingStyleTableList, TableOperationButton } from '../../components/common';
import { load } from '../../util';
import getKeywordsRanking from './service';

const { moment } = new MomentUtils();

const tableTitle = {
  ranking: '名次',
  keyword: '关键词',
  times: '出现次数',
  lastMonthRanking: '上月排行',
  operation: '操作',
};

const renderTrend = (ranking, lastRanking) => {
  let trendIcon;
  const cRanking = ranking || 0;
  const cLastRanking = lastRanking || 0;
  const renderLastRanking = (lastRanking > 10 || !lastRanking) ? '未上榜' : Math.abs(cRanking - cLastRanking);
  if (ranking < lastRanking || !lastRanking) {
    trendIcon = <ArrowUpwardIcon />;
  } else if (ranking > lastRanking) {
    trendIcon = <ArrowDownwardIcon />;
  } else if (ranking === lastRanking) {
    trendIcon = <RankingSame />;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {trendIcon}
      {renderLastRanking}
    </div>
  );
};

const RankingTable = () => {
  const [rankingList, setRankingList] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(moment().format('YYYY-MM'));

  const fetchRankingList = async (query) => {
    load.open();
    const data = await getKeywordsRanking(query);
    const dataToUse = data.map((item) => ({
      ranking: item.ranking,
      keyword: item.keyword,
      times: item.kwcounter,
      lastMonthRanking: renderTrend(item.ranking, item.lastMonthRanking),
      operation: <TableOperationButton buttonText="查看对话" keyword={item.keyword} date={date} />,
    }));
    load.close();
    setRankingList(dataToUse);
  };

  useEffect(() => {
    (async () => fetchRankingList({ date }))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const mapTableContentToTab = [
    { title: '录入关键词', content: <RankingStyleTableList tableTitle={tableTitle} tableInfoArr={rankingList} /> },
    // { title: 'SA排行', content: <RankingStyleTableList /> },
  ];
  const tabArr = mapTableContentToTab.map((item) => item.title);
  const renderTableContent = mapTableContentToTab
    .find((item) => item.title === tabArr[tabValue])
    .content;

  return (
    <Table
      headerComponent={(
        <HeaderChildren
          title="排行榜"
          tabArr={tabArr}
          tabValue={tabValue}
          setTabValue={setTabValue}
          date={date}
          setDate={setDate}
        />
      )}
    >
      {rankingList && renderTableContent}
    </Table>
  );
};

export default RankingTable;
