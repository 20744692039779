/* eslint-disable camelcase */
import MomentUtils from '@date-io/moment';
import { http } from '../../util';

const { moment } = new MomentUtils();

const replaceAvatarUrl = (data) => {
  if (data.length) {
    return data.map((resItem) => {
      const newItem = Object.assign(resItem, {});

      if (resItem.from.avatar) newItem.from.avatar = resItem.from.avatar.replace('http:', 'https:');
      if (resItem.to.avatar) newItem.to.avatar = resItem.from.avatar.replace('http:', 'https:');

      return newItem;
    });
  }

  return data;
};

const search = async (condition) => {
  const params = { ...condition };
  if (params.startDate) {
    if (params.startTime) {
      const dateStr = params.startDate.split('T')[0];
      const timeStr = params.startTime.split('T')[1];
      params.startDate = `${dateStr}T${timeStr}`;
    } else {
      params.startDate = moment(params.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
    }
  }
  if (params.endDate) {
    if (params.endTime) {
      const dateStr = params.endDate.split('T')[0];
      const timeStr = params.endTime.split('T')[1];
      params.endDate = `${dateStr}T${timeStr}`;
    } else {
      params.endDate = moment(params.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
    }
  }

  delete params.startTime;
  delete params.endTime;

  const res = await http.get('/api/conversationHistory', { params });
  return replaceAvatarUrl(res);
};

const getConversationByFromToDate = async (item) => {
  const { from, to, msgtime } = item;
  const fromUserId = from.userid || from.external_userid;
  const toUserId = to.external_userid || to.userid;

  const res = await http.get(`/api/conversationHistory/${fromUserId}/${toUserId}/${msgtime}`);

  return replaceAvatarUrl(res);
};

export default {
  search,
  getConversationByFromToDate,
};
