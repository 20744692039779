/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '@material-ui/core';
import { ROUTE_PATH } from '../../../constants';
import { getUserInfo, getWebPortalInfo } from '../auth.services';
import {
  toast,
  storage,
  STORAGE_KEY,
  load,
} from '../../../util';

const PAGE_CONSTANT = {
  URL_PARAMS: {
    STATE: 'state=',
    CODE: 'code=',
  },
  WX_STATE: 'getCode',
};

const useStyles = makeStyles({
  login: {
    backgroundImage: `url(${'/bg.jpg'})`,
    backgroundSize: 'cover',
    height: '100%',
  },
  textColor: {
    color: '#FFFFFF',
  },
  qrCode: {
    backgroundColor: '#ffffff',
    marginTop: '159px',
    width: '281px',
    height: '351px',
    padding: '40px 59px 60px 41px',
  },
  scanText: {
    marginTop: '72px',
    fontFamily: 'Burberry Styrene',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '20px',
  },
  requestAccess: {
    marginTop: '13px',
    fontFamily: 'Burberry Styrene',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '20px',
    textDecoration: 'underline',
  },
  requestAccessBorder: {
    borderBottom: '2px solid #FFFFFF',
  },
  colorMask: {
    background: 'rgba(0,0,0,.7)',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
});

export default function MainContent(initSideNavTab) {
  const classes = useStyles();
  const history = useHistory();
  const fakeWxCodeRef = useRef(null);
  const [appInfo, setAppInfo] = useState(null);

  const login = async (wxCode) => {
    if (!wxCode) {
      return;
    }

    const res = await getUserInfo(wxCode);
    const {
      userId, userName, avatar, name,
    } = res.user;

    storage.save(STORAGE_KEY.TOKEN, res.token);
    storage.save(STORAGE_KEY.EXPIRETIME, res.exp);
    storage.save(STORAGE_KEY.USERINFO, {
      userId, name, userName, avatar: avatar && avatar.replace('http:', 'https:'),
    });
    initSideNavTab();
    history.push(ROUTE_PATH.RANKING);
  };

  const localLogin = () => {
    const fakeWxCode = fakeWxCodeRef.current.value;
    login(fakeWxCode);
    load.open();
  };

  const getQrCodeInfo = async () => {
    try {
      const webPortalInfo = await getWebPortalInfo();
      setAppInfo(webPortalInfo);
      load.close();
      if (!document.getElementById('wx_reg')) {
        return;
      }
      window.WwLogin({
        id: 'wx_reg',
        state: PAGE_CONSTANT.WX_STATE,
        ...webPortalInfo,
      });
    } catch (error) {
      console.log(error);
      load.close();
      toast.error('Login Failed.');
    }
  };

  useEffect(() => {
    load.open();
    if (window.location.search) {
      const paramsString = window.location.search.substring(1);
      const searchParams = new URLSearchParams(paramsString);
      const urlState = searchParams.get('state');
      const wxCode = searchParams.get('code');

      if (!urlState || urlState !== PAGE_CONSTANT.WX_STATE) {
        toast.error('Invalid state.');
        load.close();
        return;
      }
      login(wxCode);
    } else {
      if (appInfo) {
        load.close();
        return;
      }
      getQrCodeInfo();
    }
  });

  return appInfo ? (
    <div className={classes.login}>
      <div className={classes.colorMask}>
        <div className={classes.qrCode} id="wx_reg" />
        {process.env.NODE_ENV !== 'production' && (
          <div className={classes.requestAccess}>
            <Input className={`${classes.textColor} ${classes.requestAccessBorder}`} placeholder="Local login code" inputRef={fakeWxCodeRef} inputProps={{ 'aria-label': 'Local login code' }} />
            <Button className={classes.textColor} onClick={localLogin}>Local Login</Button>
          </div>
        )}
      </div>
    </div>
  ) : (<></>);
}
