import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TableHeader from '../tableHeader';
import DialogMessageTable from './components/dialogMessageTable';
import Table from '../table';
import NoDataPage from '../noDataPage';

const useStyles = makeStyles({
  dialogPanelContainer: {
    width: '100%',
  },
});

const DialogPanel = ({
  data,
  dataAmount,
  hasPagination,
  pageSize,
  currentPage,
  onPageChange,
  viewDetail,
  classes,
  noDataPageText,
  ...header
}) => {
  const styles = useStyles();

  // eslint-disable-next-line react/jsx-props-no-spreading
  const makeTableHead = () => (<TableHeader {... header} />);

  return (
    <div className={`${styles.dialogTableContainer} ${classes}`}>
      <Table
        headerComponent={makeTableHead()}
        hasPagination={hasPagination}
        onPageChange={onPageChange}
        pageSize={pageSize}
        currentPage={currentPage}
        dataAmount={dataAmount}
      >
        {
          (!data || (Array.isArray(data) && data.length === 0))
            ? <NoDataPage text={noDataPageText} />
            : <DialogMessageTable data={data} viewDetail={viewDetail} />
        }
      </Table>
    </div>
  );
};

DialogPanel.propTypes = {
  headerComponent: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.string,
  hasPagination: PropTypes.bool,
  dataAmount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  viewDetail: PropTypes.func,
};

DialogPanel.defaultProps = {
  headerComponent: null,
  children: null,
  classes: '',
  hasPagination: false,
  dataAmount: 0,
  pageSize: null,
  currentPage: null,
  onPageChange: () => {},
  viewDetail: () => {},
};

export default DialogPanel;
