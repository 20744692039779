export const MIN_APP_WIDTH = '1024px';
export const MAX_APP_WIDTH = '1500px';

export const LOGIN_STATE = 'GET_CODE';

export const ROUTE_PATH = {
  LOGIN: '/login',
  RANKING: '/ranking',
  SEARCH: '/search',
  ALERT: '/alert/:keyword?/:date?',
  KEYWORDS: '/keywordsEntry',
};

export const UPLOAD = {
  LIMIT_SIZE: 1024 * 1024, // 1MB
  LIMIT_COUNT: 10,
};

export const ACTION_TYPE = {
  SIDE_NAV: {
    CHOOSE_TAB: 'CHOOSE_TAB',
  },
};

export const SIDENAV_TABS = [
  {
    id: 'ranking',
    name: '排行榜',
    icon: '/Nav-ranking.png',
    router: ROUTE_PATH.RANKING,
  },
  {
    id: 'search',
    name: '搜索',
    icon: '/Nav-search.png',
    router: ROUTE_PATH.SEARCH,
  },
  {
    id: 'alert',
    name: '关键词提醒',
    icon: '/Nav-alert.png',
    router: ROUTE_PATH.ALERT,
  },
  {
    id: 'typein',
    name: '录入关键词',
    icon: '/Nav-Input.png',
    router: ROUTE_PATH.KEYWORDS,
  },
];
