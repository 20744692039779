const createTableListInfo = (keyword, type, times) => ({
  词汇: keyword,
  类别: type,
  出现次数: times,
  录入日期: '2020-09-21',
});

const mockData = [
  createTableListInfo('风衣', '关键词', 199),
  createTableListInfo('微信小程序', '关键词', 172),
  createTableListInfo('笨蛋', '警示词', 167),
  createTableListInfo('瞎子', '警示词', 172),
  createTableListInfo('T恤', '关键词', 167),
  createTableListInfo('缺货', '关键词', 172),
  createTableListInfo('裤子', '关键词', 219),
  createTableListInfo('尺寸', '关键词', 189),
  createTableListInfo('发货', '关键词', 178),
  createTableListInfo('在吗', '关键词', 167),
  createTableListInfo('价格', '关键词', 275),
  createTableListInfo('店铺地址', '关键词', 112),
];

export default mockData;
