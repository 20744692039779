/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../styles/theme';
import { Load } from '../components/common';

export default {
  open: function () {
    this.load(true);
  },
  close: function () {
    this.load(false);
  },
  load: function (isOpen) {
    const mountPoint = document.getElementById('loadHelper');

    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Load isOpen={isOpen} />
      </ThemeProvider>,
      mountPoint,
    );
  },
};
