import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    flex: 1,
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flext-start',
    backgroundColor: '#E5E5E5',
    padding: '24px 100px 24px 100px',
    overflow: 'auto',
  },
});

export default function MainContent({ wrappedComponent, hasSideNav }) {
  const classes = useStyles();

  return (
    <Grid item className={`${classes.content} ${hasSideNav ? classes.mainContentContainer : ''}`}>
      {wrappedComponent}
    </Grid>
  );
}
