import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
});

const Body = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {children}
    </div>
  );
};

export default Body;
