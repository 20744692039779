/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Header from './header';
import SideNav from './sideNav';
import MainContentContainer from './mainContentContainer';
import { emitter, EMITTER_KEY } from '../../util';
import action from '../../actions';
import { SIDENAV_TABS } from '../../constants';

const useStyles = makeStyles({
  pageGrid: {
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  sideNavContainer: {
    height: '100%',
    flex: '0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  normal: {
    flexBasis: '240px',
    transition: 'flex-basis 1s',
    overflow: 'hidden',
    minWidth: '240px',
  },
  narrow: {
    flexBasis: '24px',
    transition: 'flex-basis .3s',
    overflow: 'hidden',
  },
  headerBarContainer: {
    position: 'static',
    height: '7vh',
    minHeight: '64px',
    background: '#FFFFFF',
    boxShadow: 'none',
    borderBottom: '1px solid #EEEEEE',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  bodyContainer: {
    height: '100%',
    flex: '1 1',
    overflowX: 'auto',
  },
  contentContainer: {
    width: '100%',
    height: '93vh',
  },
});

const DefaultLayout = ({
  hasSideNav,
  mainContent,
  chooseSideNavTab,
  dispatchChooseTab,
}) => {
  const classes = useStyles();
  const [sideNavExpand, setSideNavExpand] = useState(true);
  const history = useHistory();

  const toggleSideNav = useCallback(() => {
    setSideNavExpand(!sideNavExpand);
  }, [sideNavExpand]);

  useEffect(() => {
    emitter.addListener(EMITTER_KEY.TOGGLE_SIDENAV, toggleSideNav);

    history.listen((location) => {
      const path = location.pathname.split('/');
      const choosedTab = SIDENAV_TABS.find((item) => item.router.indexOf(path[1]) > -1);
      if (choosedTab) dispatchChooseTab({ tabId: choosedTab.id, router: choosedTab.router });
    });

    return () => {
      emitter.removeListener(EMITTER_KEY.TOGGLE_SIDENAV, toggleSideNav);
    };
  }, [chooseSideNavTab, history, toggleSideNav]);

  return (
    <Grid container className={classes.pageGrid}>
      {
        hasSideNav
          ? (
            <Grid container item xs={2} spacing={0} className={`${classes.sideNavContainer} ${sideNavExpand ? classes.normal : classes.narrow}`}>
              <SideNav
                isExpand={sideNavExpand}
                chooseTabId={chooseSideNavTab && chooseSideNavTab.tabId}
              />
            </Grid>
          ) : <></>
      }
      <Grid container item spacing={0} className={classes.bodyContainer}>
        <Grid container item className={classes.headerBarContainer}>
          <Header className={classes.headerBarContainer} hasTopFold={hasSideNav} />
        </Grid>
        <Grid container item className={classes.contentContainer}>
          <MainContentContainer
            wrappedComponent={mainContent(dispatchChooseTab)}
            hasSideNav={hasSideNav}
          />
        </Grid>
      </Grid>
      <div id="loadHelper" />
      <div id="toastHelper" />
    </Grid>
  );
};

export default connect(
  ({ sideNav }) => ({ chooseSideNavTab: sideNav.chooseSideNavTab }),
  (dispatch) => ({
    dispatchChooseTab: (chooseSideNavTab) => dispatch(action.chooseTab(chooseSideNavTab)),
  }),
)(DefaultLayout);
