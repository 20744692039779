import mockData from './mockData';
import { http } from '../../util/index';
import API from './constants';

const getData = (returnData) => (returnData ? mockData : null);

const getKeywordsList = async (query) => {
  const url = API.GET.KEYWORD_LIST;
  const result = await http.get(url, { params: query });
  return result;
};

const addKeyword = async (reqBody) => {
  const url = `${API.POST.ADD_KEYWORD}`;
  const result = await http.post(url, reqBody);
  return result.id;
};

const removeKeyword = async (reqBody) => {
  const url = `${API.DELETE.REMOVE_KEYWORD}`;
  const result = await http.delete(url, { data: reqBody });
  return result;
};

export default {
  addKeyword,
  getData,
  getKeywordsList,
  removeKeyword,
};
