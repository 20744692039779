import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { Container, Typography } from '@material-ui/core';
import { storage, help } from '../../util';
import { ROUTE_PATH, SIDENAV_TABS } from '../../constants';

const useStyles = makeStyles({
  sideNav: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundImage: `url(${'/bg.jpg'})`,
    backgroundSize: 'cover',
    color: '#ffffff',
    padding: 0,
  },
  accountOverview: {
    height: '20vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: '32px',
    paddingLeft: '32px',
    boxSizing: 'border-box',
  },
  avatar: {
    width: '60px',
    height: '60px',
  },
  avatarText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '19px',
    fontFamily: 'PingFang SC',
    fontStyle: 'normal',
  },
  accountName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  accountPosition: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tabName: {
    fontFamily: 'PingFang SC',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  tabItem: {
    height: '9vh',
    paddingLeft: '34px',
    cursor: 'pointer',
  },
  tabIconImage: {
    width: '1.5rem',
    height: '1.5rem',
  },
  tabIcon: {
    minWidth: '38px',
  },
  quit: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: '72px',
    marginBottom: '40px',
  },
  quitText: {
    width: '4vw',
    minWidth: '75px',
    fontWeight: 700,
    fontSize: '1.0rem',
    borderRadius: 0,
    borderBottom: '1px solid #FFFFFF',
    padding: 0,
  },
  colorMask: {
    background: 'rgba(0,0,0,.7)',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  choosed: {
    background: 'linear-gradient(90deg, #545454 0%, rgba(84, 84, 84, 0.4) 100%)',
    borderLeft: '4px solid white',
  },
});

export default function SideNav({ isExpand, chooseTabId }) {
  const classes = useStyles();
  const history = useHistory();

  const tabsClick = (router) => {
    history.push(router);
  };

  const quit = () => {
    storage.clear();
    history.push(ROUTE_PATH.LOGIN);
  };

  const userInfo = help.getStorageUserInfo();
  const { name, userName, avatar } = userInfo;

  // TODO: Enhancement
  return (
    <Container className={classes.sideNav}>
      <div className={classes.colorMask}>
        <div className={classes.accountOverview}>
          {isExpand ? (
            <>
              <Avatar className={classes.avatar} alt={name || userName} src={avatar} />
              <div className={classes.avatarText}>
                <Typography className={classes.accountName}>{userName}</Typography>
                <Typography className={classes.accountPosition}>管理员</Typography>
              </div>
            </>
          ) : ''}
        </div>
        <List className={classes.tabs}>
          {isExpand ? SIDENAV_TABS.map((tab) => (
            <ListItem key={`side-tab-${tab.id}`} onClick={() => tabsClick(tab.router)} className={`${classes.tabItem} ${isExpand && chooseTabId === tab.id ? classes.choosed : ''}`}>
              <ListItemIcon className={classes.tabIcon}>
                <img className={classes.tabIconImage} src={tab.icon} alt={tab.id} />
              </ListItemIcon>
              <ListItemText className={classes.tabName}>
                {tab.name}
              </ListItemText>
            </ListItem>
          )) : ''}
        </List>
        {isExpand ? (
          <div className={classes.quit}>
            <Button color="secondary" className={classes.quitText} onClick={quit}>退出登录</Button>
          </div>
        ) : ''}
      </div>
    </Container>
  );
}
