import React from 'react';

const DocMessageItem = ({
  content: {
    // doc_creator,
    link_url: linkUrl,
    title,
  },
}) => (
  <a href={linkUrl} target="blank" download>{title}</a>
);

export default DocMessageItem;
