import { http } from '../../util/index';
import API from './constants';

async function getUserInfo(wxCode) {
  const url = `${API.GET.TOKEN}`;
  const result = await http.post(url, { code: wxCode });
  return result;
}

async function getWebPortalInfo() {
  const result = await http.get(API.GET.WEBPORTALINFO);
  return result;
}

export {
  getUserInfo,
  getWebPortalInfo,
};
